import PropTypes from "prop-types";
import { useEffect, useRef } from "react";
// import IconBoxData from '../../data/iconBox/icon-box.json';
import IconBox from '../../components/IconBox/IconBox.jsx';
import SectionTitle from '../../components/SectionTitles/SectionTitle';
import Parallax from 'parallax-js';

const ServiceIconBoxThree
    = (props) => {
        const { classOption, IconBoxData, title } = props
        const sceneEl = useRef(null);
        useEffect(() => {
            const parallaxInstance = new Parallax(sceneEl.current, {
                relativeInput: true,
            })

            parallaxInstance.enable();

            return () => parallaxInstance.disable();

        }, [])
        return (
            <div className={`section section-padding-t90 section-padding-bottom ${classOption}`}>
                <div className="container">

                    <SectionTitle
                        headingOption="fz-32"
                        title={title}
                        subTitle="Texte de remplacement"
                    />

                    <div className="row row-cols-lg-3 row-cols-md-2 row-cols-sm-2 row-cols-1 mb-n6 icon-box-shape-animation">

                        {IconBoxData && IconBoxData.map((single, key) => {
                            return (
                                <div key={key} className="col mb-6" data-aos="fade-up" data-aos-delay="300">
                                    <IconBox classOption="box-border" data={single} key={key} />
                                </div>
                            );
                        })}

                        <div className="shape shape-1" id="scene" ref={sceneEl}>
                            <span data-depth="1"><img src={process.env.PUBLIC_URL + "/images/shape-animation/video-shape-1.png"} alt="" /></span>
                        </div>

                    </div>

                </div>
            </div>
        )
    }

ServiceIconBoxThree
    .propTypes = {
    classOption: PropTypes.string
};
ServiceIconBoxThree
    .defaultProps = {
    classOption: "section section-padding-t90 section-padding-bottom"
};

export default ServiceIconBoxThree

