import React from 'react'
import { NavLink } from 'react-router-dom';

const NavBar = () => {
    return (
        <nav className="site-main-menu">
            <ul>
                <li>
                    <NavLink to={process.env.PUBLIC_URL + "/"}><span className="menu-text">A la une</span></NavLink>
                </li>
                <li className="has-children">
                    <NavLink to="#"><span className="menu-text">A propos</span></NavLink>
                    <span className="menu-toggle"><i className="far fa-angle-down"></i></span>
                    <ul className="sub-menu">
                        <li><NavLink to={process.env.PUBLIC_URL + "/historique"}><span className="menu-text">Historique</span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + "/fierte"}><span className="menu-text">Fierté</span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + "/depute"}><span className="menu-text">Députés</span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + "/ministere"}><span className="menu-text">Ministères</span></NavLink></li>
                    </ul>
                </li>
                <li className="has-children">
                    <NavLink to="#"><span className="menu-text">Opportunités</span></NavLink>
                    <span className="menu-toggle"><i className="far fa-angle-down"></i></span>
                    <ul className="sub-menu">
                        <li><NavLink to={process.env.PUBLIC_URL + "/venez-voir"}><span className="menu-text">Venez voir</span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + "/investissez"}><span className="menu-text">Investissez</span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + "/emploi"}><span className="menu-text">Emplois</span></NavLink></li>
                    </ul>
                </li>
                <li>
                    <NavLink to={process.env.PUBLIC_URL + "/quinquennat"}><span className="menu-text">Quinquennat</span></NavLink>
                    {/* <ul className="sub-menu">
                        <li><NavLink to={process.env.PUBLIC_URL + "/a-faire"}><span className="menu-text">A Faire</span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + "/en-cours"}><span className="menu-text">En Cours</span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + "/fait"}><span className="menu-text">Fait</span></NavLink></li>
                    </ul> */}
                </li>
                <li >
                    <NavLink to={process.env.PUBLIC_URL + "/requete"}><span className="menu-text">Requêtes</span></NavLink>
                    {/* <span className="menu-toggle"><i className="far fa-angle-down"></i></span>
                    <ul className="sub-menu">
                        <li><NavLink to={process.env.PUBLIC_URL + "/work"}><span className="menu-text">Work</span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + `/work-details/1`}><span className="menu-text">Work Details</span></NavLink></li>
                    </ul> */}
                </li>
                {/* <li className="has-children">
                    <NavLink to={process.env.PUBLIC_URL + "/blog-grid"}><span className="menu-text">Blog</span></NavLink>
                    <span className="menu-toggle"><i className="far fa-angle-down"></i></span>
                    <ul className="sub-menu">
                        <li><NavLink to={process.env.PUBLIC_URL + "/blog-grid"}><span className="menu-text">Blog Grid</span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + "/blog-classic"}><span className="menu-text">Blog classic</span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + `/blog-details/1`}><span className="menu-text">Blog Details</span></NavLink></li>
                    </ul>
                </li> */}
                {/* <li>
                    <NavLink to={process.env.PUBLIC_URL + "/contact"}><span className="menu-text">Contact</span></NavLink>
                </li> */}
            </ul>
        </nav>
    )
}

export default NavBar
