import { useState, useEffect, useRef } from "react";
import SectionTitleTwo from "../../components/SectionTitles/SectionTitleTwo";
import Tilt from "react-parallax-tilt";
import Parallax from "parallax-js";
import { Card } from 'antd';
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import DescriptMinistereSample from "../../components/DescriptMininstereSample";

//ANT DESIGN IMPORTS
import { Collapse } from 'antd';

const items = [
    {
        key: '1',
        label: 'Réparer les nids-de-poule',
        children:
            <p>
                Plusieurs employés ont été mobilisé dans des zones rurales.
                Ils ont su reparer tous les nids-de-poule en faisant usage des goudrons de première qualité.
                Pour faciliter la circulation, des routes pour piétons ont aussi été construites.
            </p>,
    },
    {
        key: '2',
        label: "Assistance Alimentaire",
        children: <p>
            Plusieurs organisations et companies ont été contacté pour sponsoriser la distribution de
            nourritures aux familles moins privilégiées en communauté
        </p>,
    },
    {
        key: '3',
        label: "Mésures préventives contre les disastres",
        children:
            <p>
                Des réunions stratégiques ont été tenues and des plans pour faire face à des situations d'urgence
                ont été implimentes. Des refuges ont été designés pour ces cas d'urgence. La restauration et des kits
                de sécurité ont aussi été mis à la disposition de la population en cas de désastre.
            </p>,
    },
];

const DescriptionMinAbout = (props) => {
    const { Meta } = Card;
    const { title } = props;
    const [didViewCountUp, setDidViewCountUp] = useState(false);
    const onVisibilityChange = (isVisible) => {
        if (isVisible) {
            setDidViewCountUp(true);
        }
    };
    const [scale] = useState(1.04);
    const sceneEl = useRef(null);
    useEffect(() => {
        const parallaxInstance = new Parallax(sceneEl.current, {
            relativeInput: true,
        });

        parallaxInstance.enable();

        return () => parallaxInstance.disable();
    }, []);
    return (
        <div className="section section-padding-top about-section-padding-bottom-200">
            <div className="container">
                <div className="row">
                    <div className="col-xl-6 col-lg-6 col-12" data-aos="fade-up">
                        <div className="about-content-area mt-0 mb-md-10 mb-10">
                            <SectionTitleTwo
                                subTitle={title}
                                title="Nom du ministère (addresse du ministère)"
                            />

                            <div className="row row-cols-sm-1 row-cols-auto mb-n6">
                                <div className="col mb-6">
                                    <div className="about-funfact">
                                        <h6 className="text">Tâches</h6>
                                    </div>
                                    <Collapse
                                        ghost={true}
                                        items={items}
                                        bordered={false}
                                        defaultActiveKey={['1']}
                                        size="large"
                                    />;
                                </div>
                                {/* <div className="col mb-6">
                                    <div className="about-funfact">
                                        <h6 className="text">Texte de remplacement</h6>
                                        <p>Texte de remplacement</p>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>

                    <div
                        className="col-xl-6 col-lg-6 col-12"
                        data-aos="fade-up"
                        data-aos-delay="300"
                    >
                        <div className="about-image-area about-shape-animation right-0 me-0">
                            <div className="about-image js-tilt">
                                <Tilt scale={scale} transitionSpeed={4000}>
                                    <img
                                        src={process.env.PUBLIC_URL + "images/about/about-1.jpg"}
                                        alt=""
                                    />
                                </Tilt>
                            </div>
                            {/* <div className="about-image js-tilt">
                <Tilt scale={scale} transitionSpeed={4000}>
                  <img
                    src={process.env.PUBLIC_URL + "images/about/about-2.jpg"}
                    alt=""
                  />
                </Tilt>
              </div> */}
                            <div className="shape shape-1" id="scene" ref={sceneEl}>
                                <span data-depth="1">
                                    <img
                                        src={
                                            process.env.PUBLIC_URL +
                                            "images/shape-animation/video-shape-1.png"
                                        }
                                        alt=""
                                    />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DescriptionMinAbout;
