import React from 'react';
import SEO from '../../components/SEO.jsx';
import Header from "../../partials/header/Header.jsx";
import Breadcrumb from '../../container/Breadcrumb/Breadcrumb.js';
import ContactInformation from '../../container/ContactInformation/ContactInformation.js';
import GoogleMap from '../../container/Map/GoogleMap.js';
import ContactFromContainer from '../../container/ContactFromContainer/ContactFromContainer.js';
import Footer from '../../container/Footer/Footer.js';
import ScrollToTop from '../../components/ScrollToTop.jsx';

const Contact = () => {
    return (
        <React.Fragment>
            <SEO title="Exomac || Contact" />
            <Header />
            <Breadcrumb
                image="images/bg/breadcrumb-bg-five.jpg"
                title="We are an agency located in New York"
                content="A la une"
                contentTwo="Contactez nous"
            />
            <ContactInformation />
            <GoogleMap />
            <ContactFromContainer />
            <Footer />
            <ScrollToTop />
        </React.Fragment>
    )
}

export default Contact;

