import { useState, useEffect, useRef } from "react";
import SectionTitleTwo from "../../components/SectionTitles/SectionTitleTwo";
import Tilt from "react-parallax-tilt";
import Parallax from "parallax-js";
import { Card } from "antd";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import { Link } from "react-router-dom";
import SituationGeographique from "../../assets/images/about/Situation_geographique.png";
import Repartition from "../../assets/images/about/repartition.png";
import cartographie from "../../assets/images/about/cartographie.png";
import anotherRepartition from "../../assets/images/about/anotherRepartition.png";

const AboutFour = (props) => {
  const { Meta } = Card;
  const { title } = props;
  const [didViewCountUp, setDidViewCountUp] = useState(false);
  const onVisibilityChange = (isVisible) => {
    if (isVisible) {
      setDidViewCountUp(true);
    }
  };
  const [scale] = useState(1.04);
  const sceneEl = useRef(null);
  useEffect(() => {
    const parallaxInstance = new Parallax(sceneEl.current, {
      relativeInput: true,
    });

    parallaxInstance.enable();

    return () => parallaxInstance.disable();
  }, []);
  return (
    <div className="section section-padding-top about-section-padding-bottom-200">
      <div className="container">
        <div>
          <div
            className="col-xl-12 col-lg-12 col-md-12 col-sm-12"
            data-aos="fade-up"
          >
            <div>
              <SectionTitleTwo
                title="PRÉSENTATION DE LA PROVINCE"
                subTitle="SITUATION PHYSIQUE"
              />
              <div className="about-funfact">
                <div className="thumbnail">
                  <img
                    src={SituationGeographique}
                    alt="Blog Image"
                    style={{
                      borderRadius: 20,
                      boxShadow: `0 6px 6px -3px var(--bs-primary)`,
                      float: "left",
                      marginRight: "15px",
                    }}
                  />
                </div>

                <p>
                  La Province du Tanganyika, à l’instar de celles du
                  Haut-Lomami, du Lualaba et du HautKatanga, est issue du
                  démembrement de l’ancienne province du Katanga tel que l’a
                  stipulé la Constitution du 18 février 2006. Son chef-lieu est
                  Kalemie, ville côtière située au bord du Lac Tanganyika, nom
                  venu vient du nom « ETANGA YA NIA » signifiant « Lieu de
                  mélange » en Kibembe, la langue du milieu. Elle fut
                  découverte, pour les non autochtone par Messieurs Richard
                  FRANCIS BURTON et John HANNIG SPECKE en 1858. Avec ses 134 940
                  km² de superficie, elle est la 3ème plus grande Province de la
                  République Démocratique du Congo après la Tshopo 199 567 km2
                  et le Bas-Uélé 148 331 km2. Elle compte une population de 2
                  482 009 habitants et une densité de 18hab/km2 avec le
                  français, le swahili et le lingala comme langues les plus
                  parlées. Le Tanganyika est situé au Sud-est de la République
                  Démocratique du Congo entre 5° et 8.5° de latitude Sud et 26°
                  et 31° de longitude EST. Elle partage ses frontières avec les
                  Provinces du Maniema et du Sud-Kivu au Nord ; la Province de
                  Lomami à l’Ouest, celles du Haut- Lomami et du Haut-Katanga,
                  ainsi que les pays comme la Zambie au Sud et à l’Est par le
                  lac Tanganyika et la Tanzanie.
                </p>
              </div>
              <div className="about-funfact" style={{ marginTop: 20 }}>
                <p>
                  La Province a deux grands bassins hydrographiques (Le lac
                  Tanganyika et le Fleuve Congo) alimentés par un grand réseau
                  des rivières dont la Lukuga et Luvua la lufira qui déversent
                  respectivement les eaux du Lac Tanganyika et du lac Moero dans
                  le Fleuve Congo. Ces bassins constituent une grande réserve
                  d’eaux douces, dont le lac Tanganyika qui avec 650 Km de
                  longueur, 40 à 80 Km de largeur, une superficie de 32.000 Km2
                  , 18.880 km3 de volume d’eau et une moyenne pluviométrique de
                  1300 mm/an ; se classe au rang de 2ème réserve mondiale d’eau
                  douce, après le lac BAÏKAL de Russie représentant environ 18%
                  du volume d’eau douce mondial. La Province du Tanganyika
                  dispose d’une faune riche et variée. Elle comprend deux
                  régions qui supportent une biodiversité importante et unique :
                  le Rift Albertin et le lac Tanganyika. La végétation du
                  Tanganyika est répartie en deux catégories distinctes : les
                  formations forestières et les formations herbeuses, lesquelles
                  donnent lieu à un certain nombre de types de végétation : (i)
                  steppes sur hauts plateaux ; (ii) forêt de divers types des
                  régions montagneuses.
                </p>
              </div>
              <div
                className="about-funfact"
                style={{ marginTop: 20, marginBottom: 20 }}
              >
                <div className="thumbnail">
                  <img
                    src={Repartition}
                    alt="Blog Image"
                    style={{
                      borderRadius: 20,
                      boxShadow: `0 6px 6px -3px var(--bs-primary)`,
                      float: "right",
                      marginRight: "15px",
                    }}
                  />
                </div>
                <p>
                  Le climat de la province est du type Tropical Humide avec 2
                  saisons (la saison sèche allant de Juin à Août et la saison
                  Pluvieuse de Septembre à Mai) avec une pluviométrie moyenne de
                  1.300 mm /an. Le mois de décembre est le plus pluvieux et le
                  mois de juillet est le plus sec. Les meilleurs sols
                  s’aperçoivent dans la Province du Tanganyika avec une
                  prédominance dans les collectivités de Lubunda (Territoire de
                  Kongolo) et de Mulemi (Territoire de Kabalo) et dans la partie
                  située sur la rive droite du Fleuve Lualaba/Congo, au Nord de
                  la Lukuga ainsi que dans les vallées de Nyemba et de Luizi.
                  Par ailleurs, la Province possède des nappes phréatiques
                  abondantes et leurs eaux sont souvent de bonne qualité. Il y a
                  lieu d’épingler aussi la présence des sources d’eaux
                  souterraines : froides, chaudes, thermales, salines, thermo
                  minérales.
                </p>
              </div>
              <SectionTitleTwo
                // title="PRÉSENTATION DE LA PROVINCE"
                subTitle="CONTEXTE POLITICO-ADMINISTRATIF"
              />
              <div
                className="about-funfact"
                style={{ marginTop: 20, marginBottom: 20 }}
              >
                <div className="thumbnail">
                  <img
                    src={cartographie}
                    alt="Blog Image"
                    style={{
                      borderRadius: 20,
                      boxShadow: `0 6px 6px -3px var(--bs-primary)`,
                      float: "left",
                      marginRight: "15px",
                    }}
                  />
                </div>
                <p>
                  L’organisation politique de la Province repose sur deux
                  Institutions à savoir :
                  <ul>
                    <li>
                      L’Assemblée Provinciale qui compte 24 Députés dont 22 élus
                      au suffrage universel direct et 2 cooptés. Elle est
                      dirigée par un bureau de 5 Membres dont deux Femmes en
                      qualité de questeur et rapporteur adjointe.
                    </li>
                    <li>
                      Le Gouvernement Provincial est composé d’un Gouverneur,
                      d’un Vice-Gouverneur, de 10 Ministres provinciaux, de 5
                      Commissaires généraux ainsi que de 2 Secrétaires du
                      Gouvernement.
                    </li>
                  </ul>
                  L’Administration Publique et les services publics provinciaux
                  sont composés de Services Nationaux Décentralisés et
                  Déconcentrés, des Services Techniques Spécialisés ainsi que
                  des Directions Provinciales des Entreprises Publiques. Sur le
                  plan administratif, la Province de Tanganyika est subdivisée 6
                  territoires (KALEMIE, KABALO, KONGOLO, MANONO, MOBA et
                  NYUNZU), la ville de Kalemie qui comprend trois communes, 12
                  Secteurs et 17 Chefferies subdivisés en 83 Groupement.
                </p>
              </div>
              <SectionTitleTwo
                // title="PRÉSENTATION DE LA PROVINCE"
                subTitle="CONTEXTE SOCIODÉMOGRAPHIQUE"
              />
              <div
                className="about-funfact"
                style={{ marginTop: 20, marginBottom: 20 }}
              >
                <div className="thumbnail">
                  <img
                    src={anotherRepartition}
                    alt="Blog Image"
                    style={{
                      borderRadius: 20,
                      boxShadow: `0 6px 6px -3px var(--bs-primary)`,
                      float: "right",
                      marginRight: "15px",
                    }}
                  />
                </div>
                <p>
                  La Province du Tanganyika est comptée parmi les Provinces les
                  moins peuplées du pays. Sa population est estimée à 2 482 009
                  habitants, soit 18hab/km2. Les services de l’état-civil
                  existent dans toutes les Entités Territoriales Décentralisées
                  mais avec des capacités institutionnelles assez limitées pour
                  l’enregistrement des naissances. Les naissances et les décès
                  sont rarement déclarés par ignorance de la loi. Le recensement
                  administratif de la population n’est pas organisé de manière
                  systématique, ce qui pose le problème de la maîtrise du volume
                  réel de la population et de la fiabilité de résultats.
                </p>
              </div>
              <SectionTitleTwo
                // title="PRÉSENTATION DE LA PROVINCE"
                subTitle="CONTEXTE ÉCONOMIQUE"
              />
              <div
                className="about-funfact"
                style={{
                  marginTop: 20,
                  marginBottom: 20,
                }}
              >
                <p>
                  Les ravages causés par la guerre ont tendance à occulter
                  l’état déjà très avancé de dégradation de la situation
                  socio-économique du Tanganyika. Cependant, la Province possède
                  des atouts importants qui peuvent servir de socle pour son
                  développement économique avec quelques opérateurs économiques
                  dont les principales activités sont le commerce des matériels
                  de construction, le commerce des produits alimentaires
                  importés et cosmétiques, le commerce des produits pétroliers,
                  la pêche semi industriel, l’agriculture, l’élevage, le
                  tourisme ainsi que le commerce des divers. Les principaux
                  produits agricoles de la province sont : le maïs, le riz, le
                  manioc, le palmier à huile, l’arachide, le haricot, la patate
                  douce, le niébé. L’on compte également des produits non
                  agricoles notamment le ciment, l’énergie, le poisson, les
                  boissons alcooliques, le sucre, les chenilles, les fourmis
                  ailées, les champignons, l’huile de palme. Ces différents
                  produits sont destinés à la consommation locale et à
                  l’exportation.
                </p>
                <p>
                  Le sous-sol de la province du Tanganyika est riche en minerais
                  stratégiques et diversifiés (Cassitérite, le charbon, l’or, le
                  Coltan, le calcaire, le cuivre, le cobalt, le lithium, la
                  granite, le germanium, le rhénium, Plomb) dont les plus
                  importants sont les suivants :
                  <ol>
                    <li>
                      LITHIUM : La province du Tanganyika plus spécifiquement
                      dans le territoire de MANONO détient un gisement de plus
                      400 Millions des tonnes et est la première réserve
                      mondiale de cette matière très importante.
                    </li>
                    <li>
                      COLTAN : La province du Tanganyika détient à ce jour 31%
                      du gisement sur le 65% des réserves de minerais de Coltan
                      que détient la RDC sur l’échiquier continental ;
                    </li>
                    <li>
                      OR : La province du Tanganyika possède de l’Or sur le Rift
                      du Lac Tanganyika, dans le territoire Nyunzu où l’or est
                      extrait dans des carrières artisanales et aussi dans le
                      territoire de Kongolo ;
                    </li>
                    <li>
                      CUIVRE : Le Tanganyika possède d’importante gisement de
                      minerais de cuivre dont la teneur en Cu et Co est très
                      élevée dans le territoire de MOBA et Manono ;
                    </li>
                    <li>CHARBON : se trouve dans le territoire de Kalemie.</li>
                  </ol>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div
          className="col-xl-12 col-lg-12 col-12"
          data-aos="fade-up"
          data-aos-delay="300"
        >
          <div className="about-image-area about-shape-animation right-0 me-0">
            {/* <div className="about-image js-tilt">
                            <Tilt scale={scale} transitionSpeed={4000}>
                                <img
                                    src={process.env.PUBLIC_URL + "images/about/about-1.jpg"}
                                    alt=""
                                />
                            </Tilt>
                        </div> */}
            {/* <div className="about-image js-tilt">
                      <Tilt scale={scale} transitionSpeed={4000}>
                        <img
                          src={process.env.PUBLIC_URL + "images/about/about-2.jpg"}
                          alt=""
                        />
                      </Tilt>
                     </div> */}
            <div className="shape shape-1" id="scene" ref={sceneEl}>
              <span data-depth="1">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "images/shape-animation/video-shape-1.png"
                  }
                  alt=""
                />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutFour;
