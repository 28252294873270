import React from 'react';
import SEO from '../../components/SEO';
import Header from "../../partials/header/Header";
import PageBlog from '../../container/BlogGrid/PageBlog';
import CallToAction from '../../container/CallToAction/CallToAction';
import Footer from '../../container/Footer/Footer';
import ScrollToTop from '../../components/ScrollToTop.jsx';
import LandingPgBreadcrumb from '../../container/Breadcrumb/LandingPgBreadcrumb.js';
import Img7 from "../../assets/images/gouvernorat/gouv-7.jpeg"

const Landing = () => {
    return (
        <React.Fragment>
            <SEO title="Gourvenorat || Blog" />
            <Header />
            <LandingPgBreadcrumb
                image={Img7}
                title="Retrouvez toutes nos dernières nouvelles, analyses et événements"
                content="A la une"
                contentTwo="Blog"
            />
            <PageBlog />
            {/* <CallToAction /> */}
            <Footer />
            <ScrollToTop />
        </React.Fragment>
    )
}

export default Landing