import Img7 from "../../assets/images/gouvernorat/gouv-8.jpeg"
import Img8 from "../../assets/images/gouvernorat/gouv-9.jpg"
import Img9 from "../../assets/images/gouvernorat/gouv-10.jpeg"
import Img10 from "../../assets/images/gouvernorat/gouv-11.jpg"


export default [
    {
        "id": 2,
        "image": Img7,
        "largeImage": Img7,
        "author": "Written by Admin",
        "date": "26 July 2024",
        "view": "70 vues",
        "categories": [
            "App",
            "Startup"
        ],
        "name": "Félix Antoine Tshisekedi",
        "title": "A remporté l'élection présidentielle contestée de 2018, est actuellement président de la RDC.",
        "excerpt": "Beautiful and usable design can be the deciding factor between a successful and a failing business. Great designers are treated like superstars. And it’s so fulfilling to design beautiful things that people love to use.",
        "body": [
            "<p><strong>We have always known the power of digital, and we have mastered the strategy, tactics, and technology to harness it. Now, other departments have had to embrace the digital transformation that busine- sses have been talking about for so long, and those colleagues are turning to us in Marketing to provide guidance.</strong></p>",
            "<p>Even if it is a brave new world for everyone else, for marketers it is another day in the proverbial office.</p>",
            "<p>Now, the digital evolution is resonating across organizations. The pandemic has shifted internal structures and changed relationships with customers, and as a result marketers have become more valuable than ever.</p>",
            "<p><strong>The Shift Inside Organizations: Marketers Become Indispensable in a Digital World.</strong></p>",
            "<p>Marketing has often been known as the department that makes PowerPoints and polishes websites. That was not ever close to the whole truth, of course, but past company structures — in which Marketing always played second fiddle to Sales — allowed that falsehood to persist.</p>",
            "<p>The pandemic has transformed what the marketing team means to an organization: It is given us agency. It is upended the customer journey. It is redefined how sales and marketing teams should interact. And it is forced businesses to see the value that marketers provide in a digital world.</p>"
        ],
        "tags": [
            "App",
            "Branding",
            "Development",
            "Design"
        ]
    },
    {
        "id": 3,
        "image": Img8,
        "largeImage": Img8,
        "author": "Written by Admin",
        "date": "26 July 2024",
        "view": "70 vues",
        "categories": [
            "UX Design",
            "Marketing"
        ],
        "name": "Laurent Désiré Kabila",
        "title": "A mené une rébellion contre Mobutu, est devenu président après son assassinat.",
        "excerpt": "Beautiful and usable design can be the deciding factor between a successful and a failing business. Great designers are treated like superstars. And it’s so fulfilling to design beautiful things that people love to use.",
        "body": [
            "<p><strong>We have always known the power of digital, and we have mastered the strategy, tactics, and technology to harness it. Now, other departments have had to embrace the digital transformation that busine- sses have been talking about for so long, and those colleagues are turning to us in Marketing to provide guidance.</strong></p>",
            "<p>Even if it is a brave new world for everyone else, for marketers it is another day in the proverbial office.</p>",
            "<p>Now, the digital evolution is resonating across organizations. The pandemic has shifted internal structures and changed relationships with customers, and as a result marketers have become more valuable than ever.</p>",
            "<p><strong>The Shift Inside Organizations: Marketers Become Indispensable in a Digital World.</strong></p>",
            "<p>Marketing has often been known as the department that makes PowerPoints and polishes websites. That was not ever close to the whole truth, of course, but past company structures — in which Marketing always played second fiddle to Sales — allowed that falsehood to persist.</p>",
            "<p>The pandemic has transformed what the marketing team means to an organization: It is given us agency. It is upended the customer journey. It is redefined how sales and marketing teams should interact. And it is forced businesses to see the value that marketers provide in a digital world.</p>"
        ],
        "tags": [
            "App",
            "Branding",
            "Development",
            "Design"
        ]
    },
    {
        "id": 4,
        "image": Img9,
        "largeImage": Img9,
        "author": "Written by Admin",
        "date": "26 July 2024",
        "view": "70 vues",
        "categories": [
            "Startup",
            "UX Design",
            "Marketing"
        ],
        "name": "Joseph Kasavubu",
        "title": "Premier président de la RDC, a joué un rôle clé dans l'indépendance du pays.",
        "excerpt": "Beautiful and usable design can be the deciding factor between a successful and a failing business. Great designers are treated like superstars. And it’s so fulfilling to design beautiful things that people love to use.",
        "body": [
            "<p><strong>We have always known the power of digital, and we have mastered the strategy, tactics, and technology to harness it. Now, other departments have had to embrace the digital transformation that busine- sses have been talking about for so long, and those colleagues are turning to us in Marketing to provide guidance.</strong></p>",
            "<p>Even if it is a brave new world for everyone else, for marketers it is another day in the proverbial office.</p>",
            "<p>Now, the digital evolution is resonating across organizations. The pandemic has shifted internal structures and changed relationships with customers, and as a result marketers have become more valuable than ever.</p>",
            "<p><strong>The Shift Inside Organizations: Marketers Become Indispensable in a Digital World.</strong></p>",
            "<p>Marketing has often been known as the department that makes PowerPoints and polishes websites. That was not ever close to the whole truth, of course, but past company structures — in which Marketing always played second fiddle to Sales — allowed that falsehood to persist.</p>",
            "<p>The pandemic has transformed what the marketing team means to an organization: It is given us agency. It is upended the customer journey. It is redefined how sales and marketing teams should interact. And it is forced businesses to see the value that marketers provide in a digital world.</p>"
        ],
        "tags": [
            "App",
            "Branding",
            "Development",
            "Design"
        ]
    },
    {
        "id": 5,
        "image": Img10,
        "largeImage": Img10,
        "author": "Written by Admin",
        "date": "26 July 2024",
        "view": "70 vues",
        "categories": [
            "Startup",
            "Marketing"
        ],
        "name": "Patrice Emery Lumumba",
        "title": "Premier ministre démocratiquement élu, a servi comme président pour une courte période.",
        "excerpt": "Beautiful and usable design can be the deciding factor between a successful and a failing business. Great designers are treated like superstars. And it’s so fulfilling to design beautiful things that people love to use.",
        "body": [
            "<p><strong>We have always known the power of digital, and we have mastered the strategy, tactics, and technology to harness it. Now, other departments have had to embrace the digital transformation that busine- sses have been talking about for so long, and those colleagues are turning to us in Marketing to provide guidance.</strong></p>",
            "<p>Even if it is a brave new world for everyone else, for marketers it is another day in the proverbial office.</p>",
            "<p>Now, the digital evolution is resonating across organizations. The pandemic has shifted internal structures and changed relationships with customers, and as a result marketers have become more valuable than ever.</p>",
            "<p><strong>The Shift Inside Organizations: Marketers Become Indispensable in a Digital World.</strong></p>",
            "<p>Marketing has often been known as the department that makes PowerPoints and polishes websites. That was not ever close to the whole truth, of course, but past company structures — in which Marketing always played second fiddle to Sales — allowed that falsehood to persist.</p>",
            "<p>The pandemic has transformed what the marketing team means to an organization: It is given us agency. It is upended the customer journey. It is redefined how sales and marketing teams should interact. And it is forced businesses to see the value that marketers provide in a digital world.</p>"
        ],
        "tags": [
            "App",
            "Branding",
            "Development",
            "Design"
        ]
    },
]