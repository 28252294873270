import React from 'react';
import SEO from '../../components/SEO.jsx';
import Header from "../../partials/header/Header.jsx";
import Breadcrumb from '../../container/Breadcrumb/Breadcrumb.js';
import ServiceIconBoxTwo from '../../container/service/ServiceIconBoxTwo.js';
import ServiceSkill from '../../container/service/ServiceSkill.js';
import AboutSix from '../../container/About/AboutSix.js';
import FunfactTwo from '../../container/Funfact/FunfactTwo.js';
import ContactInformationThree from '../../container/ContactInformation/ContactInformationThree.js';
import Footer from '../../container/Footer/Footer.js';
import ScrollToTop from '../../components/ScrollToTop.jsx';

const aproposList = [
    {
        "id": "1",
        "icon": "images/svg/linea/linea-arrows-info.svg",
        "title": "Historique",
        "desc": "Texte de remplacement",
        "pageLink": "En savoir plus",
        "link": "/historique"
    },
    {
        "id": "2",
        "icon": "images/svg/linea/linea-arrows-info.svg",
        "title": "Fierte",
        "desc": "Texte de remplacement",
        "pageLink": "En savoir plus",
        "link": "/fierte"
    },
    {
        "id": "3",
        "icon": "images/svg/linea/linea-arrows-info.svg",
        "title": "Deputes",
        "desc": "Texte de remplacement",
        "pageLink": "En savoir plus",
        "link": "/deputes"
    },
    {
        "id": "4",
        "icon": "images/svg/linea/linea-arrows-info.svg",
        "title": "Ministeres",
        "desc": "Texte de remplacement",
        "pageLink": "En savoir plus",
        "link": "/ministeres"
    }

]
const Apropos = () => {
    return (
        <React.Fragment>
            <SEO title="GT || Apropos" />
            <Header />
            <Breadcrumb
                image="images/bg/breadcrumb-bg-three.jpg"
                title="A propos de nous"
                content="A la une"
                contentTwo="A propos"
            />
            <ServiceIconBoxTwo IconBoxData={aproposList} title={"A propos"} />
            <ServiceSkill />
            {/* <AboutSix /> */}
            {/* <FunfactTwo /> */}
            {/* <ContactInformationThree /> */}
            <Footer />
            <ScrollToTop />
        </React.Fragment>
    )
}

export default Apropos