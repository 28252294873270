import React from 'react'
import Sample from '../../components/Sample';
import SiteClassicData from '../../data/blog/SiteClassic.json';
import VenezVoirBlogItem from '../../components/Blog/VenezVoirBlogItem.jsx';
import SEO from '../../components/SEO';
import Header from "../../partials/header/Header";
import Breadcrumb from '../../container/Breadcrumb/Breadcrumb';
import PageBlog from '../../container/BlogGrid/PageBlog';
import CallToAction from '../../container/CallToAction/CallToAction';
import Footer from '../../container/Footer/Footer';
import ScrollToTop from '../../components/ScrollToTop.jsx';

const VenezVoir = () => {
  return (
    <React.Fragment>
      <SEO title="Gourvenorat | Venez voir" />
      <Header />
      <Breadcrumb
        image="images/bg/breadcrumb-bg-four.jpg"
        title="Retrouvez toutes nos dernières nouvelles, analyses et événements"
        content="A la une"
        contentTwo="Venez voir | Site touristiques"
      />
      <div className="section section-padding fix">
        <div className="container">
          <div className="row row-cols-lg-2 row-cols-md-2 row-cols-sm-2 row-cols-1 mb-n6">
            {SiteClassicData && SiteClassicData.map((single, key) => {
              return (
                <div key={key} className="col mb-6" data-aos="fade-up">
                  <VenezVoirBlogItem data={single} key={key} />
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <Footer />
      <ScrollToTop />
    </React.Fragment>

  )
}

export default VenezVoir