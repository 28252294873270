import React from 'react'
import Sample from '../../components/Sample'
import HistoriqueSample from '../../components/HistoriqueSample'
import InvestissezAbout from '../../components/InvestissezAbout'

const Investissez = () => {
  return (
    <InvestissezAbout title={"GT || Investissez"}
      titleDesc={"Investissez"}
      contentTwo={"Investissez"} />
  )
}

export default Investissez