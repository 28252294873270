import React from 'react'
import HistoriqueSample from '../../components/HistoriqueSample'
const Historique = () => {
  return (
    <HistoriqueSample title={"GT || Historique"}
      titleDesc={"Notre Historique"}
      contentTwo={"Historique"} />
  )
}

export default Historique