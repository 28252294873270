import React, { useState, useEffect } from 'react';
import AOS from "aos";
import { Form, Modal, Input, Select } from 'antd';


import MainRoutes from "./routes";

function App() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    AOS.init({
      offset: 80,
      duration: 1000,
      once: true,
      easing: "ease",
    });
    AOS.refresh();
    setIsModalOpen(true);

  }, []);

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select>
        <Option value="243">+243</Option>
        <Option value="87">+87</Option>
      </Select>
    </Form.Item>
  );
  return (
    <React.Fragment>
      <MainRoutes />
      <Modal
        title="Pour être notifié dès qu'il y a du nouveau ici, écrivez:"
        open={isModalOpen}
        okText="Envoyez"
        cancelText="Plus tard"
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form
          name="register"
        // style={{
        //     maxWidth: 600,
        // }}
        // scrollToFirstError
        >
          <Form.Item
            name="email"
            rules={[
              {
                type: 'email',
                message: 'The input is not valid E-mail!',
              },
              {
                required: true,
                message: 'Please input your E-mail!',
              },
            ]}
          >
            <Input placeholder='E-mail' />
          </Form.Item>
          <Form.Item
            name="téléphone"
            rules={[
              {
                required: true,
                message: 'Please input your phone number!',
              },
            ]}
          >
            <Input
              // addonBefore={prefixSelector}
              style={{
                width: '100%',
              }}
              placeholder='Téléphone'
            />
          </Form.Item>
        </Form>
      </Modal>
    </React.Fragment>
  )
}

export default App;
