import React from 'react'
import Sample from '../../components/Sample';
import BlogClassicData from '../../data/blog/BlogClassic.json';
import MinistereBlogItem from '../../components/Blog/MinistereBlogItem';
import SEO from '../../components/SEO';
import Header from "../../partials/header/Header";
import Breadcrumb from '../../container/Breadcrumb/Breadcrumb';
import PageBlog from '../../container/BlogGrid/PageBlog';
import CallToAction from '../../container/CallToAction/CallToAction';
import Footer from '../../container/Footer/Footer';
import ScrollToTop from '../../components/ScrollToTop.jsx';


const Ministere = () => {
  return (
    <React.Fragment>
      <SEO title="Gourvenorat || Blog" />
      <Header />
      <Breadcrumb
        image="images/bg/breadcrumb-bg-four.jpg"
        title="Les ministères qui assurent le bon fonctionnement de notre belle province"
        content="A la une"
        contentTwo="Blog"
      />
      <div className="section section-padding fix">
        <div className="container">
          <div className="row row-cols-lg-3 row-cols-md-2 row-cols-sm-2 row-cols-1 mb-n6">
            {BlogClassicData && BlogClassicData.map((single, key) => {
              return (
                <div key={key} className="col mb-6" data-aos="fade-up">
                  <MinistereBlogItem data={single} key={key} />
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <Footer />
      <ScrollToTop />
    </React.Fragment>

  )
}

export default Ministere