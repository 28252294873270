import Apropos from "./Apropos";
import Historique from "./Historique";
import Depute from "./Depute";
import Ministere from "./Ministere";
import Fierte from "./Fierte";
import DescriptionMinistere from "./DescriptionMinistere";
import ProjetsMinistere from "./ProjetsMinistere";

export default [
  {
    path: "/apropos",
    component: Apropos,
  },
  {
    path: "/historique",
    component: Historique,
  },
  {
    path: "/depute",
    component: Depute,
  },
  {
    path: "/ministere",
    component: Ministere,
  },
  {
    path: "/fierte",
    component: Fierte,
  },
  {
    path: "/ministere-description",
    component: DescriptionMinistere,
  },
  {
    path: "/projets-description",
    component: ProjetsMinistere,
  },
];
