import React from 'react';
import { NavLink } from 'react-router-dom';
import {
    getClosest,
    getSiblings,
    slideToggle,
    slideUp,
} from "../../../utils";

const MobileNavMenu = () => {
    const onClickHandler = (e) => {
        const target = e.currentTarget;
        const parentEl = target.parentElement;
        if (
            parentEl?.classList.contains("menu-toggle") ||
            target.classList.contains("menu-toggle")
        ) {
            const element = target.classList.contains("icon")
                ? parentEl
                : target;
            const parent = getClosest(element, "li");
            const childNodes = parent.childNodes;
            const parentSiblings = getSiblings(parent);
            parentSiblings.forEach((sibling) => {
                const sibChildNodes = sibling.childNodes;
                sibChildNodes.forEach((child) => {
                    if (child.nodeName === "UL") {
                        slideUp(child, 1000);
                    }
                });
            });
            childNodes.forEach((child) => {
                if (child.nodeName === "UL") {
                    slideToggle(child, 1000);
                }
            });
        }
    };
    return (
        <nav className="site-mobile-menu">
            <ul>
                <li className="has-children">
                    <NavLink to={process.env.PUBLIC_URL + "/"}><span className="menu-text">A la une</span></NavLink>
                </li>
                <li className="has-children">
                    <NavLink to={process.env.PUBLIC_URL + "/apropos"}><span className="menu-text">A propos</span></NavLink>
                    <span className="menu-toggle" onClick={onClickHandler}><i className="icon fa fa-angle-down"></i></span>
                    <ul className="sub-menu">
                        <li><NavLink to={process.env.PUBLIC_URL + "/historique"}><span className="menu-text">Historique</span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + "/fierte"}><span className="menu-text">Fierte</span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + "/depute"}><span className="menu-text">Deputes</span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + "/ministere"}><span className="menu-text">Ministeres</span></NavLink></li>
                    </ul>
                </li>
                <li>
                    <NavLink to={process.env.PUBLIC_URL + "/opportunite"}><span className="menu-text">Opportunites</span></NavLink>
                    <span className="menu-toggle" onClick={onClickHandler}><i className="icon fa fa-angle-down"></i></span>
                    <ul className="sub-menu">
                        <li><NavLink to={process.env.PUBLIC_URL + "/venez-voir"}><span className="menu-text">Venez voir</span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + "/investissez"}><span className="menu-text">Investissez</span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + "/emploi"}><span className="menu-text">Emplois</span></NavLink></li>
                    </ul>
                </li>
                <li>
                    <NavLink to={process.env.PUBLIC_URL + "/quinquennat"}><span className="menu-text">Quinquennat</span></NavLink>
                    {/* <span className="menu-toggle" onClick={onClickHandler}><i className="icon fa fa-angle-down"></i></span>

                    <ul className="sub-menu">
                        <li><NavLink to={process.env.PUBLIC_URL + "/a-faire"}><span className="menu-text">A Faire</span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + "/en-cours"}><span className="menu-text">En Cours</span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + "/fait"}><span className="menu-text">Fait</span></NavLink></li>
                    </ul> */}
                </li>
                <li className="has-children">
                    <NavLink to={process.env.PUBLIC_URL + "/requete"}><span className="menu-text">Requetes</span></NavLink>
                </li>
                {/* <li>
                    <NavLink to={process.env.PUBLIC_URL + "/contact"}><span className="menu-text">Contact</span></NavLink>
                </li> */}
            </ul>
        </nav>
    )
}

export default MobileNavMenu;
