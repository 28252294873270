import PropTypes from "prop-types";
import React from 'react';
import { Link } from "react-router-dom";

const MinistereBlogItem = ({ data }) => {
    return (
        <div className="blog">
            <div className="thumbnail">
                <Link to={process.env.PUBLIC_URL + `/blog-details/${data.id}`} className="image"><img src={process.env.PUBLIC_URL + data.image} alt="Blog Image" /></Link>
            </div>
            <div className="info">
                {/* <ul className="meta">
                    <li><i className="far fa-calendar"></i>{data.date}</li>
                    <li><i className="far fa-eye"></i>{data.view}</li>
                </ul> */}
                <h3 className="title"><Link to={process.env.PUBLIC_URL + `/blog-details/${data.id}`}>Nom du ministère</Link></h3>
                {/* <Link to={process.env.PUBLIC_URL + `/blog-details/${data.id}`} className="link"> <mark>En savoir plus</mark> </Link> */}
                <div>
                    <Link to={process.env.PUBLIC_URL + `/projets-description`} className="link"> <mark>Projets</mark> </Link>
                    <Link to={process.env.PUBLIC_URL + `/ministere-description`} className="link"> <mark style={{ left: 216 }}>Description</mark> </Link>
                </div>
            </div>
        </div>
    )
}

MinistereBlogItem.propTypes = {
    data: PropTypes.object
};

export default MinistereBlogItem
