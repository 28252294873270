import PropTypes from "prop-types";
import React from 'react';
import { Link } from "react-router-dom";

const FiertéBlogItem = ({ data }) => {
    return (
        <div className="blog">
            <div className="section-title-two" data-aos="fade-up">
                <span className="sub-title">{data.name}</span>
                {/* <h3 className="title" dangerouslySetInnerHTML={{ __html: "Felix Antoine Tshisekedi" }} /> */}
            </div>
            <div className="thumbnail">
                {/* <Link to={process.env.PUBLIC_URL + `/blog-details/${data.id}`} className="image"><img src={process.env.PUBLIC_URL + data.image} alt="Blog Image" /></Link> */}
                <Link to={process.env.PUBLIC_URL + "#"} className="image"><img src={process.env.PUBLIC_URL + data.image} alt="Blog Image" /></Link>
            </div>
            <div className="info">
                {/* <h3 className="title"><Link to={process.env.PUBLIC_URL + `/blog-details/${data.id}`}>{data.title}</Link></h3> */}
                <h3 className="title"><Link to={process.env.PUBLIC_URL + "#"}>{data.title}</Link></h3>
                {/* <Link to={process.env.PUBLIC_URL + `/blog-details/${data.id}`} className="link"> <mark>En savoir plus</mark> </Link> */}
                <Link to={process.env.PUBLIC_URL + `#`} className="link"> <mark>voir plus</mark> </Link>
            </div>
        </div>
    )
}

FiertéBlogItem.propTypes = {
    data: PropTypes.object,
    title: PropTypes.string,
};
// FiertéBlogItem.propTypes = {
//     subTitle: PropTypes.string,
//     title: PropTypes.string,
//     classOption: PropTypes.string
// };
// FiertéBlogItem.defaultProps = {
//     classOption: "section-title-two"
// };

export default FiertéBlogItem
