import PropTypes from "prop-types";
import React from "react";
import ReactVivus from "react-vivus";
import { Link } from "react-router-dom";
import {
  Card,
} from "antd";
import {
    InfoOutlined
  } from "@ant-design/icons";

const IconBox = ({ data, classOption }) => {
  return (
    <div
    //   style={{ border: `3px solid ${data.backgroundColor}` }}
      className={`icon-box text-center`}
    >
      <Card
        bordered={true}
        style={{
          marginBottom: 16,
          borderRadius: "50px",
          boxShadow: `0 6px 6px -3px ${data.backgroundColor}`,
        }}
        // hoverable={true}
      >
        <div className="icon">
          {/* <ReactVivus
            id={`servicesvg-${data.id}`}
            option={{
              file: data.icon,
              animTimingFunction: "EASE",
              type: "oneByOne",
              delay: 80,
            }}
          /> */}
          <InfoOutlined style={{ fontSize:10, border:`3px solid ${data.backgroundColor}`, borderRadius:50}}/>
        </div>
        <div className="content">
          <h3 className="title">{data.title}</h3>
          <div className="desc">
            <p>{data.desc}</p>
          </div>
          <Link to={data.link} className="link">
            {data.pageLink}
          </Link>
        </div>
      </Card>
    </div>
  );
};

IconBox.propTypes = {
  data: PropTypes.object,
  classOption: PropTypes.string,
};

IconBox.defaultProps = {
  classOption: "icon-box text-center",
};

export default IconBox;
