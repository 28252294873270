import React from "react";
import SEO from "../components/SEO";
import Header from "../partials/header/Header";
import Breadcrumb from "../container/Breadcrumb/Breadcrumb";
import HistoriqueAboutFour from "../container/About/HistoriqueAboutFour";
import Video from "../container/Video/Video";
import AboutFive from "../container/About/AboutFive";
import TestimonialContainer from "../container/Testimonial/TestimonialContainer";
import CallToActionTwo from "../container/CallToAction/CallToActionTwo";
import Footer from "../container/Footer/Footer";
import ScrollToTop from "../components/ScrollToTop.jsx";
import EmploiMockup from "./EmploiMockup.js";
import { Card, Avatar, List, Space, Button } from "antd";
import {
  EyeOutlined,
  MessageOutlined,
  StarOutlined,
  UserOutlined,
} from "@ant-design/icons";

// const data = Array.from({
//   length: 2,
// }).map((_, i) => ({
//   href: "https://ant.design",
//   title: `Directeur financier ${i}`,
//   description:
//     "Nous recherchons un(e) candidat(e) idéal(e) pour le poste susmentionné.",
//     content:
//       `Entreprise: ONATRA || Postuler: emploi@onatra.com || Expiration: 27 août 2024`,
// }));

const data = [
  {
    title: `Directeur financier`,
    description:
      "Nous recherchons un(e) candidat(e) idéal(e) pour le poste susmentionné.",
    content: `Entreprise: ONATRA || Postuler: emploi@onatra.com || Expiration: 27 août 2024`,
  },
  {
    title: `Comptable`,
    description:
      "Nous recherchons un(e) candidat(e) idéal(e) pour le poste susmentionné.",
    content: `Entreprise: Ministère des finances || Postuler: postuler@finances.com || Expiration: 03 octobre 2024`,
  },
];
const IconText = ({ icon, text }) => (
  <Space>
    {React.createElement(icon)}
    {text}
  </Space>
);

const EmploisSample = (props) => {
  const { title, titleDesc, contentTwo } = props;

  return (
    <React.Fragment>
      <SEO title={title} />
      <Header />
      <Breadcrumb
        image="images/bg/breadcrumb-bg.jpg"
        title={titleDesc}
        content="A la une"
        contentTwo={contentTwo && contentTwo}
      />
      <div className="section about-section-padding-bottom-200 pt-10">
        <div className="container">
          <Card 
            style={{
                borderRadius: 20,
                boxShadow: `0 6px 6px -3px var(--bs-primary)`,
            }}
          >
            <List
              itemLayout="vertical"
              size="large"
              pagination={{
                onChange: (page) => {
                  console.log(page);
                },
                pageSize: 3,
              }}
              dataSource={data}
              //   footer={
              //     <div>
              //       <b>ant design</b> footer part
              //     </div>
              //   }
              renderItem={(item) => (
                <List.Item
                  key={item.title}
                  actions={[
                    <IconText
                      icon={EyeOutlined}
                      text="156"
                      key="list-vertical-like-o"
                    />,
                    <IconText
                      icon={MessageOutlined}
                      text="2"
                      key="list-vertical-message"
                    />,
                  ]}
                  extra={
                    <Button type="primary" shape="round" size="small">
                      Appliquer
                    </Button>
                  }
                >
                  <List.Item.Meta
                    avatar={
                      <UserOutlined
                        style={{
                          background: "var(--bs-primary)",
                          color: "var(--bs-white)",
                          fontSize: 20,
                          border: "2px solid var(--bs-primary)",
                          padding: 5,
                          borderRadius: 20,
                        }}
                      />
                    }
                    title={<a href={item.href}>{item.title}</a>}
                    description={item.description}
                  />
                  {item.content}
                </List.Item>
              )}
            />
          </Card>
        </div>
      </div>
      <Footer />
      <ScrollToTop />
    </React.Fragment>
  );
};

export default EmploisSample;
