import { useState, useEffect, useRef } from "react";
import Iframe from "react-iframe";
import ReactPlayer from "react-player/youtube";
import SectionTitleTwo from "../../components/SectionTitles/SectionTitleTwo";
import Tilt from "react-parallax-tilt";
import Parallax from "parallax-js";
import { Card } from "antd";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
//ANT DESIGN IMPORTS
import { Collapse } from "antd";

const items = [
  {
    key: "1",
    label: "Réparer les nids-de-poule",
    children: (
      <p>
        Plusieurs employés ont été mobilisé dans des zones rurales. Ils ont su
        reparer tous les nids-de-poule en faisant usage des goudrons de première
        qualité. Pour faciliter la circulation, des routes pour piétons ont
        aussi été construites.
      </p>
    ),
  },
  {
    key: "2",
    label: "Assistance Alimentaire",
    children: (
      <p>
        Plusieurs organisations et companies ont été contacté pour sponsoriser
        la distribution de nourritures aux familles moins privilégiées en
        communauté
      </p>
    ),
  },
  {
    key: "3",
    label: "Mésures préventives contre les disastres",
    children: (
      <p>
        Des réunions stratégiques ont été tenues and des plans pour faire face à
        des situations d'urgence ont été implimentes. Des refuges ont été
        designés pour ces cas d'urgence. La restauration et des kits de sécurité
        ont aussi été mis à la disposition de la population en cas de désastre.
      </p>
    ),
  },
];

const FaitSample = (props) => {
  const { Meta } = Card;
  const { title } = props;
  const [didViewCountUp, setDidViewCountUp] = useState(false);
  const onVisibilityChange = (isVisible) => {
    if (isVisible) {
      setDidViewCountUp(true);
    }
  };
  const [scale] = useState(1.04);
  // const sceneEl = useRef(null);
  // useEffect(() => {
  //     const parallaxInstance = new Parallax(sceneEl.current, {
  //         relativeInput: true,
  //     });

  //     parallaxInstance.enable();

  //     return () => parallaxInstance.disable();
  // }, []);
  return (
    <div className="section section-padding-top about-section-padding-bottom-200">
      <div className="container">
        <div className="row">
          <div className="col-xl-6 col-lg-6 col-12" data-aos="fade-up">
            <div className="about-content-area mt-0 mb-md-10 mb-10">
              <SectionTitleTwo
                subTitle={title}
                title="Développements économiques"
              />

              <div className="row row-cols-sm-1 row-cols-auto mb-n6">
                <div className="col mb-6">
                  <div className="about-funfact">
                    <Collapse
                      ghost={true}
                      items={items}
                      bordered={false}
                      defaultActiveKey={["1"]}
                      size="large"
                    />
                    ;
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-6 col-lg-6 col-12">
            {/* <ReactPlayer url="https://youtu.be/88NAc5-OLsQ?si=EH8NEv4tto_mwvAr" controls /> */}

            <Card
              style={{
                borderRadius: 20,
                boxShadow: `0 6px 6px -3px var(--bs-primary)`,
              }}
            >

              <div className="player-wrapper">
                <ReactPlayer
                  className="react-player"
                  url="https://youtu.be/88NAc5-OLsQ?si=EH8NEv4tto_mwvAr"
                  width="100%"
                // height="100%"
                />
              </div>

            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FaitSample;
