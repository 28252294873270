import React from 'react'
import Sample from '../../components/Sample'
import HistoriqueSample from '../../components/HistoriqueSample'
import EmploisSample from '../../components/EmploisSample'

const Emploi = () => {
  return (
    <EmploisSample title={"GT || Emplois"}
      titleDesc={"Emplois"}
      contentTwo={"Emplois"} />
  )
}

export default Emploi