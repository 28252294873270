import Landing from "./Landing";
import PageNotFound from "./PageNotFound";

export default [
    {
        path: "/",
        component: Landing,
      },
      {
        path: "/landing",
        component: Landing,
      },
      {
        path: "*",
        component: Landing,
      },
]