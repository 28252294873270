import React from 'react'
import Sample from '../../components/Sample'

const EnCours = () => {
  return (
    <Sample title={"GT || En Cours"}
    titleDesc={"En Cours"}
    contentTwo={"En Cours"}/>
  )
}

export default EnCours