import React from 'react'
import Sample from '../../components/Sample'

const AFaire = () => {
  return (
    
    <Sample title={"GT || A Faire"}
    titleDesc={"A Faire"}
    contentTwo={"A Faire"}/>
  )
}

export default AFaire