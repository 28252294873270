import React from 'react'
import PropTypes from "prop-types";
import FierteSample from '../../components/FierteSample'
import SEO from '../../components/SEO'
import Header from '../../partials/header/Header'
import Breadcrumb from '../../container/Breadcrumb/Breadcrumb'
import PageBlog from '../../container/BlogGrid/PageBlog'
import Footer from '../../container/Footer/Footer'
import ScrollToTop from '../../components/ScrollToTop'
import SectionTitleTwo from '../../components/SectionTitles/SectionTitleTwo';
import FiertéPageBlog from '../../container/BlogGrid/FiertéPageBlog';

const Fierte = () => {
  return (
    // <FierteSample
    //   title={"GT || Fierte"}
    //   titleDesc={"Félix Antoine Tshisekedi"}
    //   contentTwo={"Fierte"}
    // />
    <React.Fragment>
      <SEO title="Gourvenorat || Blog" />
      <Header />
      <Breadcrumb
        image="images/bg/breadcrumb-bg-four.jpg"
        title="Quelques personnes parmi tant d'autres qui ont fait la fierté de notre belle province"
        content="A la une"
        contentTwo="Fierté"
      />
      <FiertéPageBlog />
      {/* <CallToAction /> */}
      <Footer />
      <ScrollToTop />
    </React.Fragment>
  )
}

export default Fierte