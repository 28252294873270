import React from 'react';
import SEO from '../../components/SEO.jsx';
import Header from "../../partials/header/Header.jsx";
import Breadcrumb from '../../container/Breadcrumb/Breadcrumb.js';
import ServiceIconBoxTwo from '../../container/service/ServiceIconBoxTwo.js';
import ServiceSkill from '../../container/service/ServiceSkill.js';
import AboutSix from '../../container/About/AboutSix.js';
import FunfactTwo from '../../container/Funfact/FunfactTwo.js';
import ContactInformationThree from '../../container/ContactInformation/ContactInformationThree.js';
import Footer from '../../container/Footer/Footer.js';
import ScrollToTop from '../../components/ScrollToTop.jsx';
import HistoriqueAboutFour from '../../container/About/HistoriqueAboutFour';

const quinquennatList = [
    {
        "id": "1",
        "icon": "images/svg/linea/linea-arrows-info.svg",
        "title": "A Faire",
        "desc": "Texte de remplacement",
        "pageLink": "En savoir plus",
        "link": "#",
        "backgroundColor": "#e12729",
    },
    {
        "id": "2",
        "icon": "images/svg/linea/linea-arrows-info.svg",
        "title": "En Cours",
        "desc": "Texte de remplacement",
        "pageLink": "En savoir plus",
        "link": "#",
        "backgroundColor": "#03396c",

    },
    {
        "id": "3",
        "icon": "images/svg/linea/linea-arrows-info.svg",
        "title": "Fait",
        "desc": "Texte de remplacement",
        "pageLink": "En savoir plus",
        "link": "/fait",
        "backgroundColor": "#607c3c",

    },

]

const Quinquennat = () => {
    const titleDesc = "Quinquennat";
    return (
        <React.Fragment>
            <SEO title="GT || Apropos" />
            <Header />
            <Breadcrumb
                image="images/bg/breadcrumb-bg-three.jpg"
                title="Quinquennat"
                content="A la une"
                contentTwo="Quinquennat"
            />
            <ServiceIconBoxTwo IconBoxData={quinquennatList} title={"Quinquennat"} />
            {/* <HistoriqueAboutFour title={titleDesc} /> */}
            <Footer />
            <ScrollToTop />
        </React.Fragment>
    )
}

export default Quinquennat