import React from 'react';
import SEO from '../../components/SEO.jsx';
import Header from "../../partials/header/Header.jsx";
import Breadcrumb from '../../container/Breadcrumb/Breadcrumb.js';
import ServiceIconBoxTwo from '../../container/service/ServiceIconBoxTwo.js';
import ServiceSkill from '../../container/service/ServiceSkill.js';
import AboutSix from '../../container/About/AboutSix.js';
import FunfactTwo from '../../container/Funfact/FunfactTwo.js';
import ContactInformationThree from '../../container/ContactInformation/ContactInformationThree.js';
import Footer from '../../container/Footer/Footer.js';
import ScrollToTop from '../../components/ScrollToTop.jsx';

const opportuniteList = [

  {
    "id": "1",
    "icon": "images/svg/linea/linea-arrows-info.svg",
    "title": "VenezVoir",
    "desc": "Texte de remplacement",
    "pageLink": "En savoir plus",
    "link": "/venez-voir"
  },
  {
    "id": "2",
    "icon": "images/svg/linea/linea-arrows-info.svg",
    "title": "Investissez",
    "desc": "Texte de remplacement",
    "pageLink": "En savoir plus",
    "link": "/investissez"
  },

  {
    "id": "3",
    "icon": "images/svg/linea/linea-arrows-info.svg",
    "title": "Emploi",
    "desc": "Texte de remplacement",
    "pageLink": "En savoir plus",
    "link": "/emploi"
  },
  //   {
  //       "id": "4",
  //       "icon": "images/svg/linea/linea-arrows-info.svg",
  //       "title": "Opportunite",
  //       "desc": "Texte de remplacement",
  //       "pageLink": "En savoir plus",
  //       "link": "/opportunite"
  //   }

]
const Opportunite = () => {
  return (
    <React.Fragment>
      <SEO title="GT || Opportunite" />
      <Header />
      <Breadcrumb
        image="images/bg/breadcrumb-bg-three.jpg"
        title="Opportunites"
        content="A la une"
        contentTwo="Opportunites"
      />
      <ServiceIconBoxTwo IconBoxData={opportuniteList} title={"Opportunite"} />
      <ServiceSkill />
      {/* <AboutSix /> */}
      {/* <FunfactTwo /> */}
      {/* <ContactInformationThree /> */}
      <Footer />
      <ScrollToTop />
    </React.Fragment>
  )
}

export default Opportunite