import AFaire from "./AFaire";
import EnCours from "./EnCours";
import Fait from "./Fait";
import Quinquennat from "./Quinquennat";


export default [
  {
    path: "/a-faire",
    component: AFaire,
  },
  {
    path: "/en-cours",
    component: EnCours,
  },
  {
    path: "/fait",
    component: Fait,
  },
  {
    path: "/quinquennat",
    component: Quinquennat,
  },
];
