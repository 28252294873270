import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { BOM } from "../../services/definedMethod";
import { Card } from "antd";

const BlogItem = ({ data }) => {
  return (
    <div className="blog pb-10">
      <Card
        style={{
          borderRadius: 20,
          boxShadow: `0 6px 6px -3px var(--bs-primary)`,
        }}
      >
        <div className="thumbnail">
          <Link
            // to={process.env.PUBLIC_URL + `/blog-details/${data.id}`}
            to={process.env.PUBLIC_URL + "#"}
            className="image"
          >
            <img
              src={process.env.PUBLIC_URL + data.image}
              style={{
                // width:500,
                height: 270,
              }}
              alt="Blog Image"
            />
          </Link>
        </div>
        <div className="info">
          <ul className="meta">
            <li>
              <i className="far fa-calendar"></i>
              {data.date}
            </li>
            <li>
              <i className="far fa-eye"></i>
              {data.view}
            </li>
          </ul>
          <h3 className="title">
            <Link to={process.env.PUBLIC_URL + `/blog-details/${data.id}`}>
              {BOM.SubstringFormat(data.title, 50)}
            </Link>
          </h3>
          {/* <Link to={process.env.PUBLIC_URL + `/blog-details/${data.id}`} className="link"> <mark>En savoir plus</mark> </Link> */}
          <Link to={process.env.PUBLIC_URL + `/`} className="link">
            {" "}
            <mark>voir plus</mark>{" "}
          </Link>
        </div>
      </Card>
    </div>
  );
};

BlogItem.propTypes = {
  data: PropTypes.object,
};

export default BlogItem;
