import { useState, useEffect, useRef } from "react";
import SectionTitleTwo from "../../components/SectionTitles/SectionTitleTwo";
import Tilt from "react-parallax-tilt";
import Parallax from "parallax-js";
import { Card } from 'antd';
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import { Link } from "react-router-dom";

const InvestissezFour = (props) => {
    const { Meta } = Card;
    const { title } = props;
    const [didViewCountUp, setDidViewCountUp] = useState(false);
    const onVisibilityChange = (isVisible) => {
        if (isVisible) {
            setDidViewCountUp(true);
        }
    };
    const [scale] = useState(1.04);
    const sceneEl = useRef(null);
    useEffect(() => {
        const parallaxInstance = new Parallax(sceneEl.current, {
            relativeInput: true,
        });

        parallaxInstance.enable();

        return () => parallaxInstance.disable();
    }, []);
    return (
        <div className="section section-padding-top about-section-padding-bottom-200">
            <div className="container">
                <div>
                    <div className="row row-cols-lg-1 row-cols-md-2 row-cols-sm-2 row-cols-1 mb-n6" data-aos="fade-up">
                        <div>
                            {/* SEARCH PARAMETERS */}
                            <div className="row row-cols-lg-3 row-cols-md-2 row-cols-sm-2 row-cols-1 mb-n6">
                                <div className="col-md-12 col-12 mb-4">
                                    <select>
                                        <option selected>Statut</option>
                                        <option value="1">une</option>
                                        <option value="2">deux</option>
                                        <option value="3">trois</option>
                                    </select>
                                </div>
                                <div className="col-md-12 col-12 mb-4">
                                    <input
                                        type="text"
                                        placeholder="Opportunité"
                                        name="name"

                                    />
                                </div>
                                <div className="col-md-12 col-12 mb-4">
                                    <select>
                                        <option selected>Ministère</option>
                                        <option value="1">une</option>
                                        <option value="2">deux</option>
                                        <option value="3">trois</option>
                                    </select>
                                </div>
                            </div>
                            {/* JOB DESCRIPTION */}
                            <div className="row row-cols-lg-2 row-cols-md-1 row-cols-sm-1 row-cols-1 mb-n6">
                                <div>
                                    <div style={{ marginTop: 100, border: "2px solid #e12729", borderRadius: 10, padding: 10 }}>
                                        <h6 className="text">Statut: Disponible</h6>
                                        <h6 className="text">Opportunité: Exploitation d’un terrain remplie d’or</h6>
                                        <h6 className="text">Ministère : Mines </h6>
                                        <h6 className="text">Contact: mine@tanganyika.com & 0826539542</h6>
                                        <h6 className="text" style={{ fontSize: 12 }}><i>modifié le 5 juillet 2024</i></h6>
                                        <Link to="#"><i>7 Commentaires</i></Link>
                                    </div>
                                </div>
                                <div>
                                    <div style={{ marginTop: 100, border: "2px solid #1e4a28", borderRadius: 10, padding: 10 }}>
                                        <h6 className="text">Statut: Saisie</h6>
                                        <h6 className="text">Opportunité: Construction d’une gare de train</h6>
                                        <h6 className="text">Ministère : Transport</h6>
                                        <h6 className="text">Contact: transport@tanganyika.com & 0995632100</h6>
                                        <h6 className="text" style={{ fontSize: 12 }}><i>modifié le 6 juin 2024</i></h6>
                                        <Link to="#"><i>5 Commentaires</i></Link>
                                    </div>
                                </div>
                            </div>
                            {/* <div style={{ marginTop: 100, border: "1px solid #1e4a28", borderRadius: 10, padding: 10 }} className="row row-cols-lg-2 row-cols-md-2 row-cols-sm-2 row-cols-1 mb-n6">
                                
                            </div> */}

                        </div>
                    </div>
                </div>

                <div className="col-xl-12 col-lg-12 col-12" data-aos="fade-up" data-aos-delay="300" >
                    <div className="about-image-area about-shape-animation right-0 me-0">
                        {/* <div className="about-image js-tilt">
                            <Tilt scale={scale} transitionSpeed={4000}>
                                <img
                                    src={process.env.PUBLIC_URL + "images/about/about-1.jpg"}
                                    alt=""
                                />
                            </Tilt>
                        </div> */}
                        {/* <div className="about-image js-tilt">
                      <Tilt scale={scale} transitionSpeed={4000}>
                        <img
                          src={process.env.PUBLIC_URL + "images/about/about-2.jpg"}
                          alt=""
                        />
                      </Tilt>
                     </div> */}
                        <div className="shape shape-1" id="scene" ref={sceneEl}>
                            <span data-depth="1">
                                <img
                                    src={
                                        process.env.PUBLIC_URL +
                                        "images/shape-animation/video-shape-1.png"
                                    }
                                    alt=""
                                />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default InvestissezFour;
