import React from 'react'
import Sample from '../../components/Sample'

const Fait = () => {
  return (
    <Sample title={"GT || Fait"}
    titleDesc={"Fait"}
    contentTwo={"Fait"}/>
  )
}

export default Fait