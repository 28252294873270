import PropTypes from "prop-types";
import React from 'react';
import { Link } from "react-router-dom";

const VenezVoirBlogItem = ({ data }) => {
    return (
        <div className="blog">
            <div className="section-title-two" data-aos="fade-up">
                <span className="sub-title" style={{ fontWeight: 700 }}>{data.name}</span>
                {/* <h3 className="title" dangerouslySetInnerHTML={{ __html: "Felix Antoine Tshisekedi" }} /> */}
            </div>
            <h5 className="title" style={{ fontWeight: 400 }}>Addresse du site touristique</h5>

            <div className="thumbnail">
                {/* <Link to={process.env.PUBLIC_URL + `/blog-details/${data.id}`} className="image"><img src={process.env.PUBLIC_URL + data.image} alt="Blog Image" /></Link> */}
                <Link to={process.env.PUBLIC_URL + "#"} className="image"><img src={process.env.PUBLIC_URL + data.image} alt="Blog Image" /></Link>
            </div>
            <div className="info">
                {/* <ul className="meta">
                    <li><i className="far fa-calendar"></i>{data.date}</li>
                    <li><i className="far fa-eye"></i>{data.view}</li>
                </ul> */}
                <h3 className="title"><Link to={process.env.PUBLIC_URL + `/blog-details/${data.id}`}>Appétit (le petit texte qui donne envie de venir)</Link></h3>
                {/* <Link to={process.env.PUBLIC_URL + `/blog-details/${data.id}`} className="link"> <mark>En savoir plus</mark> </Link> */}
                <div>
                    <Link to={process.env.PUBLIC_URL + `#`} className="link"> <mark>25$/personne</mark> </Link>
                    <Link to={process.env.PUBLIC_URL + `#`} className="link" style={{ marginLeft: 150 }}> <mark style={{ left: 216 }}>voir plus…</mark> </Link>
                </div>
            </div>
        </div>
    )
}

VenezVoirBlogItem.propTypes = {
    data: PropTypes.object
};

export default VenezVoirBlogItem
