import Emploi from "./Emploi";
import Investissez from "./Investissez";
import VenezVoir from "./VenezVoir";
import Opportunite from "./Opportunite";


export default [
  {
    path: "/emploi",
    component: Emploi,
  },
  {
    path: "/investissez",
    component: Investissez,
  },
  {
    path: "/venez-voir",
    component: VenezVoir,
  },
  {
    path: "/opportunite",
    component: Opportunite,
  },
];
