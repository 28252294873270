import React from 'react';
import SEO from '../components/SEO';
import Header from "../partials/header/Header";
import Breadcrumb from '../container/Breadcrumb/Breadcrumb';
import AboutFour from '../container/About/AboutFour';
import Video from '../container/Video/Video';
import AboutFive from '../container/About/AboutFive';
import TestimonialContainer from '../container/Testimonial/TestimonialContainer';
import CallToActionTwo from '../container/CallToAction/CallToActionTwo';
import Footer from '../container/Footer/Footer';
import ScrollToTop from '../components/ScrollToTop.jsx';
import DescriptionMinAbout from '../container/About/DescriptionMinAbout.js';

const DescriptMinistereSample = (props) => {
    const { title, titleDesc, contentTwo } = props;

    return (
        <React.Fragment>
            <SEO title={title} />
            <Header />
            <Breadcrumb
                image="images/bg/breadcrumb-bg.jpg"
                title={titleDesc}
                content="A la une"
                contentTwo={"Description"}
            />
            <DescriptionMinAbout title={titleDesc} />
            <Footer />
            <ScrollToTop />
        </React.Fragment>
    )
}

export default DescriptMinistereSample