import Img1 from "../../assets/images/gouvernorat/gouv-1.jpeg"
import Img2 from "../../assets/images/gouvernorat/gouv-2.jpeg"
import Img3 from "../../assets/images/gouvernorat/gouv-3.jpeg"
import Img4 from "../../assets/images/gouvernorat/gouv-4.jpeg"
import Img5 from "../../assets/images/gouvernorat/gouv-5.jpeg"
import Img6 from "../../assets/images/gouvernorat/gouv-6.jpeg"


export default [
    {
        "id": 2,
        "image": Img1,
        "largeImage": Img1,
        "author": "Written by Admin",
        "date": "22 July 2024",
        "view": "70 vues",
        "categories": [
            "App",
            "Startup"
        ],
        "name": "Félix Antoine Tshisekedi",
        "title": "La cour d'appel confirme le gouverneur Christian Kitungwa",
        "excerpt": "Beautiful and usable design can be the deciding factor between a successful and a failing business. Great designers are treated like superstars. And it’s so fulfilling to design beautiful things that people love to use.",
        "body": [
            "<p><strong>We have always known the power of digital, and we have mastered the strategy, tactics, and technology to harness it. Now, other departments have had to embrace the digital transformation that busine- sses have been talking about for so long, and those colleagues are turning to us in Marketing to provide guidance.</strong></p>",
            "<p>Even if it is a brave new world for everyone else, for marketers it is another day in the proverbial office.</p>",
            "<p>Now, the digital evolution is resonating across organizations. The pandemic has shifted internal structures and changed relationships with customers, and as a result marketers have become more valuable than ever.</p>",
            "<p><strong>The Shift Inside Organizations: Marketers Become Indispensable in a Digital World.</strong></p>",
            "<p>Marketing has often been known as the department that makes PowerPoints and polishes websites. That was not ever close to the whole truth, of course, but past company structures — in which Marketing always played second fiddle to Sales — allowed that falsehood to persist.</p>",
            "<p>The pandemic has transformed what the marketing team means to an organization: It is given us agency. It is upended the customer journey. It is redefined how sales and marketing teams should interact. And it is forced businesses to see the value that marketers provide in a digital world.</p>"
        ],
        "tags": [
            "App",
            "Branding",
            "Development",
            "Design"
        ]
    },
    {
        "id": 3,
        "image": Img2,
        "largeImage": Img2,
        "author": "Written by Admin",
        "date": "24 July 2024",
        "view": "70 vues",
        "categories": [
            "UX Design",
            "Marketing"
        ],
        "name": "Laurent Désiré Kabila",
        "title": "Christian Kitungwa du regroupement politique Agissons et Bâtissons (AB) a été élu, ce lundi 29 avril gouverneur de la province du Tanganyika.",
        "excerpt": "Beautiful and usable design can be the deciding factor between a successful and a failing business. Great designers are treated like superstars. And it’s so fulfilling to design beautiful things that people love to use.",
        "body": [
            "<p><strong>We have always known the power of digital, and we have mastered the strategy, tactics, and technology to harness it. Now, other departments have had to embrace the digital transformation that busine- sses have been talking about for so long, and those colleagues are turning to us in Marketing to provide guidance.</strong></p>",
            "<p>Even if it is a brave new world for everyone else, for marketers it is another day in the proverbial office.</p>",
            "<p>Now, the digital evolution is resonating across organizations. The pandemic has shifted internal structures and changed relationships with customers, and as a result marketers have become more valuable than ever.</p>",
            "<p><strong>The Shift Inside Organizations: Marketers Become Indispensable in a Digital World.</strong></p>",
            "<p>Marketing has often been known as the department that makes PowerPoints and polishes websites. That was not ever close to the whole truth, of course, but past company structures — in which Marketing always played second fiddle to Sales — allowed that falsehood to persist.</p>",
            "<p>The pandemic has transformed what the marketing team means to an organization: It is given us agency. It is upended the customer journey. It is redefined how sales and marketing teams should interact. And it is forced businesses to see the value that marketers provide in a digital world.</p>"
        ],
        "tags": [
            "App",
            "Branding",
            "Development",
            "Design"
        ]
    },
    {
        "id": 4,
        "image": Img3,
        "largeImage": Img3,
        "author": "Written by Admin",
        "date": "26 July 2024",
        "view": "70 vues",
        "categories": [
            "Startup",
            "UX Design",
            "Marketing"
        ],
        "name": "Joseph Kasavubu",
        "title": "Christian Kitungwa confirmé gouverneur élu par la cour d'appel",
        "excerpt": "Beautiful and usable design can be the deciding factor between a successful and a failing business. Great designers are treated like superstars. And it’s so fulfilling to design beautiful things that people love to use.",
        "body": [
            "<p><strong>We have always known the power of digital, and we have mastered the strategy, tactics, and technology to harness it. Now, other departments have had to embrace the digital transformation that busine- sses have been talking about for so long, and those colleagues are turning to us in Marketing to provide guidance.</strong></p>",
            "<p>Even if it is a brave new world for everyone else, for marketers it is another day in the proverbial office.</p>",
            "<p>Now, the digital evolution is resonating across organizations. The pandemic has shifted internal structures and changed relationships with customers, and as a result marketers have become more valuable than ever.</p>",
            "<p><strong>The Shift Inside Organizations: Marketers Become Indispensable in a Digital World.</strong></p>",
            "<p>Marketing has often been known as the department that makes PowerPoints and polishes websites. That was not ever close to the whole truth, of course, but past company structures — in which Marketing always played second fiddle to Sales — allowed that falsehood to persist.</p>",
            "<p>The pandemic has transformed what the marketing team means to an organization: It is given us agency. It is upended the customer journey. It is redefined how sales and marketing teams should interact. And it is forced businesses to see the value that marketers provide in a digital world.</p>"
        ],
        "tags": [
            "App",
            "Branding",
            "Development",
            "Design"
        ]
    },
    {
        "id": 5,
        "image": Img4,
        "largeImage": Img4,
        "author": "Written by Admin",
        "date": "26 July 2024",
        "view": "70 vues",
        "categories": [
            "Startup",
            "Marketing"
        ],
        "name": "Patrice Emery Lumumba",
        "title": "Christian Kitungwa appelle les autorités provinciales à privilégier la paix",
        "excerpt": "Beautiful and usable design can be the deciding factor between a successful and a failing business. Great designers are treated like superstars. And it’s so fulfilling to design beautiful things that people love to use.",
        "body": [
            "<p><strong>We have always known the power of digital, and we have mastered the strategy, tactics, and technology to harness it. Now, other departments have had to embrace the digital transformation that busine- sses have been talking about for so long, and those colleagues are turning to us in Marketing to provide guidance.</strong></p>",
            "<p>Even if it is a brave new world for everyone else, for marketers it is another day in the proverbial office.</p>",
            "<p>Now, the digital evolution is resonating across organizations. The pandemic has shifted internal structures and changed relationships with customers, and as a result marketers have become more valuable than ever.</p>",
            "<p><strong>The Shift Inside Organizations: Marketers Become Indispensable in a Digital World.</strong></p>",
            "<p>Marketing has often been known as the department that makes PowerPoints and polishes websites. That was not ever close to the whole truth, of course, but past company structures — in which Marketing always played second fiddle to Sales — allowed that falsehood to persist.</p>",
            "<p>The pandemic has transformed what the marketing team means to an organization: It is given us agency. It is upended the customer journey. It is redefined how sales and marketing teams should interact. And it is forced businesses to see the value that marketers provide in a digital world.</p>"
        ],
        "tags": [
            "App",
            "Branding",
            "Development",
            "Design"
        ]
    },
    {
        "id": 6,
        "image": Img5,
        "largeImage": Img5,
        "author": "Written by Admin",
        "date": "26 July 2024",
        "view": "70 vues",
        "categories": [
            "Startup",
            "UX Design"
        ],
        "name": "Simon Kimbangu",
        "title": "Christian Kitungwa rassuré de l'accompagnement de l'IGF",
        "excerpt": "Beautiful and usable design can be the deciding factor between a successful and a failing business. Great designers are treated like superstars. And it’s so fulfilling to design beautiful things that people love to use.",
        "body": [
            "<p><strong>We have always known the power of digital, and we have mastered the strategy, tactics, and technology to harness it. Now, other departments have had to embrace the digital transformation that busine- sses have been talking about for so long, and those colleagues are turning to us in Marketing to provide guidance.</strong></p>",
            "<p>Even if it is a brave new world for everyone else, for marketers it is another day in the proverbial office.</p>",
            "<p>Now, the digital evolution is resonating across organizations. The pandemic has shifted internal structures and changed relationships with customers, and as a result marketers have become more valuable than ever.</p>",
            "<p><strong>The Shift Inside Organizations: Marketers Become Indispensable in a Digital World.</strong></p>",
            "<p>Marketing has often been known as the department that makes PowerPoints and polishes websites. That was not ever close to the whole truth, of course, but past company structures — in which Marketing always played second fiddle to Sales — allowed that falsehood to persist.</p>",
            "<p>The pandemic has transformed what the marketing team means to an organization: It is given us agency. It is upended the customer journey. It is redefined how sales and marketing teams should interact. And it is forced businesses to see the value that marketers provide in a digital world.</p>"
        ],
        "tags": [
            "App",
            "Branding",
            "Development",
            "Design"
        ]
    },
    {
        "id": 6,
        "image": Img6,
        "largeImage": Img6,
        "author": "Written by Admin",
        "date": "26 July 2024",
        "view": "70 vues",
        "categories": [
            "Startup",
            "UX Design"
        ],
        "name": "Simon Kimbangu",
        "title": "Christian Kitungwa porté à la tête de la coordination provinciale de l’Union sacrée",
        "excerpt": "Beautiful and usable design can be the deciding factor between a successful and a failing business. Great designers are treated like superstars. And it’s so fulfilling to design beautiful things that people love to use.",
        "body": [
            "<p><strong>We have always known the power of digital, and we have mastered the strategy, tactics, and technology to harness it. Now, other departments have had to embrace the digital transformation that busine- sses have been talking about for so long, and those colleagues are turning to us in Marketing to provide guidance.</strong></p>",
            "<p>Even if it is a brave new world for everyone else, for marketers it is another day in the proverbial office.</p>",
            "<p>Now, the digital evolution is resonating across organizations. The pandemic has shifted internal structures and changed relationships with customers, and as a result marketers have become more valuable than ever.</p>",
            "<p><strong>The Shift Inside Organizations: Marketers Become Indispensable in a Digital World.</strong></p>",
            "<p>Marketing has often been known as the department that makes PowerPoints and polishes websites. That was not ever close to the whole truth, of course, but past company structures — in which Marketing always played second fiddle to Sales — allowed that falsehood to persist.</p>",
            "<p>The pandemic has transformed what the marketing team means to an organization: It is given us agency. It is upended the customer journey. It is redefined how sales and marketing teams should interact. And it is forced businesses to see the value that marketers provide in a digital world.</p>"
        ],
        "tags": [
            "App",
            "Branding",
            "Development",
            "Design"
        ]
    }
]