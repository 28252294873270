import { useState, useEffect, useRef } from "react";
import SectionTitleTwo from "../../components/SectionTitles/SectionTitleTwo";
import Tilt from "react-parallax-tilt";
import Parallax from "parallax-js";
import { Card } from 'antd';
import BlogClassicData from '../../data/blog/BlogClassic.json';
import BlogItem from '../../components/Blog/BlogItem';
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";

// ANT DESIGN IMPORTS
import { Typography } from 'antd';
const { Paragraph } = Typography;

const AboutFierteFour = (props) => {
    const [expanded, setExpanded] = useState(false);
    const { Meta } = Card;
    const { title } = props;
    const [didViewCountUp, setDidViewCountUp] = useState(false);
    const onVisibilityChange = (isVisible) => {
        if (isVisible) {
            setDidViewCountUp(true);
        }
    };
    const [scale] = useState(1.04);
    const sceneEl = useRef(null);
    useEffect(() => {
        const parallaxInstance = new Parallax(sceneEl.current, {
            relativeInput: true,
        });

        parallaxInstance.enable();

        return () => parallaxInstance.disable();
    }, []);
    return (
        <div className="section section-padding-top about-section-padding-bottom-200">
            <div className="container">
                <div className="row">
                    <div className="col-xl-6 col-md-6 col-lg-6 col-12" data-aos="fade-up">
                        <div className="about-content-area mt-0 mb-md-10 mb-10">
                            <SectionTitleTwo
                                subTitle={title}
                                title={title}
                            />

                            <div className="row row-cols-sm-2 row-cols-auto mb-n6">
                                {/* <div className="col mb-6">
                                    <div className="about-funfact">
                                        <h6 className="text">Texte de remplacement</h6>
                                        <p>Texte de remplacement</p>
                                    </div>
                                </div> */}
                                <div className="about-image js-tilt">
                                    <Tilt scale={scale} transitionSpeed={4000}>
                                        <img
                                            src={process.env.PUBLIC_URL + "images/about/about-1.jpg"}
                                            alt=""
                                        />
                                    </Tilt>
                                </div>
                                <div className="col mb-6">
                                    <div className="about-funfact">
                                        <h6 className="text">Texte de remplacement</h6>
                                        <Paragraph
                                            ellipsis={{
                                                rows: 3,
                                                expandable: true,
                                                symbol: "Voir plus",
                                                onExpand: () => {
                                                    console.log('Expanded:');
                                                },
                                            }}
                                        >Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                            when an unknown printer took a galley of type and scrambled it to make a type
                                            specimen book.
                                        </Paragraph>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="about-content-area mt-0 mb-md-10 mb-10">
                            <SectionTitleTwo
                                subTitle={title}
                                title={title}
                            />

                            <div className="row row-cols-sm-2 row-cols-auto mb-n6">
                                {/* <div className="col mb-6">
                                    <div className="about-funfact">
                                        <h6 className="text">Texte de remplacement</h6>
                                        <p>Texte de remplacement</p>
                                    </div>
                                </div> */}
                                <div className="about-image js-tilt">
                                    <Tilt scale={scale} transitionSpeed={4000}>
                                        <img
                                            src={process.env.PUBLIC_URL + "images/about/about-1.jpg"}
                                            alt=""
                                        />
                                    </Tilt>
                                </div>
                                <div className="col mb-6">
                                    <div className="about-funfact">
                                        <h6 className="text">Texte de remplacement</h6>
                                        <p>Texte de remplacement</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div
                        className="col-xl-6 col-lg-6 col-12"
                        data-aos="fade-up"
                        data-aos-delay="300"
                    >
                        <div className="about-image-area about-shape-animation right-0 me-0">

                            {/* <div className="about-image js-tilt">
                        <Tilt scale={scale} transitionSpeed={4000}>
                          <img
                            src={process.env.PUBLIC_URL + "images/about/about-2.jpg"}
                            alt=""
                          />
                      </Tilt>
                      </div> */}
                            <div className="about-content-area mt-0 mb-md-10 mb-10">
                                <SectionTitleTwo
                                    subTitle={title}
                                    title={title}
                                />

                                <div className="row row-cols-sm-2 row-cols-auto mb-n6">
                                    {/* <div className="col mb-6">
                                    <div className="about-funfact">
                                        <h6 className="text">Texte de remplacement</h6>
                                        <p>Texte de remplacement</p>
                                    </div>
                                </div> */}
                                    <div className="about-image js-tilt">
                                        <Tilt scale={scale} transitionSpeed={4000}>
                                            <img
                                                src={process.env.PUBLIC_URL + "images/about/about-1.jpg"}
                                                alt=""
                                            />
                                        </Tilt>
                                    </div>
                                    <div className="col mb-6">
                                        <div className="about-funfact">
                                            <h6 className="text">Texte de remplacement</h6>
                                            <p>Texte de remplacement</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="about-content-area mt-0 mb-md-10 mb-10">
                                <SectionTitleTwo
                                    subTitle={title}
                                    title={title}
                                />

                                <div className="row row-cols-sm-2 row-cols-auto mb-n6">
                                    {/* <div className="col mb-6">
                                    <div className="about-funfact">
                                        <h6 className="text">Texte de remplacement</h6>
                                        <p>Texte de remplacement</p>
                                    </div>
                                </div> */}
                                    <div className="about-image js-tilt">
                                        <Tilt scale={scale} transitionSpeed={4000}>
                                            <img
                                                src={process.env.PUBLIC_URL + "images/about/about-1.jpg"}
                                                alt=""
                                            />
                                        </Tilt>
                                    </div>
                                    <div className="col mb-6">
                                        <div className="about-funfact">
                                            <h6 className="text">Texte de remplacement</h6>
                                            <p>Texte de remplacement</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="shape shape-1" id="scene" ref={sceneEl}>
                                <span data-depth="1">
                                    <img
                                        src={
                                            process.env.PUBLIC_URL +
                                            "images/shape-animation/video-shape-1.png"
                                        }
                                        alt=""
                                    />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutFierteFour;
