import { useEffect, Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// PROTECTED ROUTE IMPORT

// MODULE ROUTE IMPORTS
import NavScrollTop from "../components/NavScrollTop";
import landingRoute from "../pages/landing/landing.route";
import aproposRoute from "../pages/apropos/apropos.route";
import requeteRoute from "../pages/requete/requete.route";
import opportuniteRoute from "../pages/opportunite/opportunite.route";
import quinquennatRoute from "../pages/quinquennat/quinquennat.route";
import contactRoute from "../pages/contact/contact.route";
// MERGED ROUTES
const allRoutes = [...landingRoute, ...aproposRoute, ...requeteRoute, ...opportuniteRoute, ...quinquennatRoute, ...contactRoute];

export default function MainRoutes() {
  return (
    <Router>
      <NavScrollTop>
        <Suspense fallback={<div />}>
          <Routes>
            {allRoutes.map((item, index) => (
              <Route
                key={index}
                path={`${process.env.PUBLIC_URL + item.path}`}
                element={<item.component />}
              />
            ))}
          </Routes>
        </Suspense>
      </NavScrollTop>
    </Router>
  );
}
